import "./App.css";
import {Fragment, useCallback, useEffect, useState} from "react";
import {Popover, Transition} from "@headlessui/react";
import {ArrowCircleDownIcon, ClipboardCopyIcon, XIcon} from "@heroicons/react/outline";

async function fetchPhoto(phoneNumber) {
  try {
    const {status, data} = await fetch(
      `http://159.223.220.13/getDataByPhone/${phoneNumber}/photo`
    ).then((res) => res.json());
    if (status) {
      return data;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}

const defaultPerson = {
  name: "Lindsay Walton",
  role: "Front-end Developer",
  imageUrl:
    "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80",
  twitterUrl: "#",
  linkedinUrl: "#",
}

function UserInfo({phoneNumber}) {
  useEffect(() => {
    fetchPhoto(phoneNumber).then(({url}) => {
      setPerson((person) => ({...defaultPerson, imageUrl: url}));
    });
  }, [phoneNumber]);

  const [person, setPerson] = useState(null);

  return (
    <li>
      <div className="space-y-4">
        <div className="aspect-w-2 aspect-h-2">
          <img
            className="object-cover shadow-lg rounded-lg bg-white"
            src={person?.imageUrl}
            alt=""
          />
        </div>

        {person && <div className="space-y-2">
          <div className="text-lg leading-6 font-medium space-y-1 text-center text-white">
            <h3>{person?.name}</h3>
            {/* <p className="text-indigo-600">{person?.role}</p> */}
          </div>
          <ul role="list" className="flex space-x-5 justify-around">
            <li>
              <a
                href={person.imageUrl}
                rel="noopener noreferrer"
                title="Download"
                target="_blank"
                className="text-gray-400 hover:text-white"
              >
                <span className="sr-only">Download</span>
                <ArrowCircleDownIcon height={20} xlinkTitle="Download"/>
              </a>
            </li>
            <li>
              <button
                href="#"
                title="Copy to clipboard"
                className="text-gray-400 hover:text-white"
                onClick={() => {
                  navigator.clipboard.writeText(person.imageUrl)
                }}
              >
                <span className="sr-only">Copy to clipboard</span>
                <ClipboardCopyIcon height={20}/>
              </button>
            </li>
          </ul>
        </div>}
      </div>
    </li>
  );
}

export default function App() {
  const [inputValue, setInputValue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(null);

  const onClick = useCallback(() => {
    setPhoneNumber(inputValue)
  }, [inputValue])
  return (
    <div className="bg-white">
      <header>
        <Popover className="relative bg-white">
          <div className="flex justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
            <div className="flex justify-start lg:w-0 lg:flex-1"></div>
          </div>

          <Transition
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <img
                        className="h-8 w-auto"
                        src="https://tailwindui.com/img/logos/workflow-mark-purple-600-to-indigo-600.svg"
                        alt="Workflow"
                      />
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>
      </header>

      <main>
        <div className="relative">
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
          <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div className="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div className="absolute inset-0">
                <img
                  className="h-full w-full object-cover"
                  src="https://images.unsplash.com/photo-1521737852567-6949f3f9f2b5?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=2830&q=80&sat=-100"
                  alt="People working on laptops"
                />
                <div className="absolute inset-0 bg-gradient-to-r from-purple-800 to-indigo-700 mix-blend-multiply" />
              </div>
              <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 className="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span className="block text-white">Telegramatar</span>
                  <span className="block text-indigo-200">
                    Один аватар на все случаи жизни.
                  </span>
                </h1>
                <p className="mt-6 max-w-lg mx-auto text-center text-xl text-indigo-200 sm:max-w-3xl">
                  В Telegramatar вы можете получить вашу аватарку из Telegram.
                  Достаточно знать номер телефона или никнейм.
                </p>
                {phoneNumber && (<div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <UserInfo phoneNumber={phoneNumber} />
                </div>)}
                <div className="mt-10 max-w-sm mx-auto sm:max-w-none flex flex-col justify-center">
                  <div className="mb-8 space-y-0 mx-auto inline-grid grid-cols-4 gap-5">
                    <div className="col-span-4">
                      <label
                        htmlFor="phone-number"
                        className="block text-sm font-medium text-white mb-2"
                      >
                        Ваш номер телефона
                      </label>
                      <div className="mt-1 relative rounded-md shadow-sm">
                        <input
                          type="text"
                          name="phone-number"
                          id="phone-number"
                          autoComplete="tel"
                          className="py-3 px-4 block w-full pl-8 w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                          placeholder="+38 (093) 073-21-72"
                          value={inputValue}
                          onChange={(e) => setInputValue(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="space-y-4 px-16 sm:space-y-0 mx-auto sm:inline-grid sm:grid-cols-2 gap-5">
                    <a
                      href="#"
                      onClick={onClick}
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-700 bg-white hover:bg-indigo-50 sm:px-8"
                    >
                      Найти аватар
                    </a>
                    <a
                      href="#"
                      className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                    >
                      Узнать больше
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
